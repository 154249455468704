<!--
 * @Description  :
 * @Author       : BigBigger
 * @Date         : 2022-02-10 14:22:33
 * @LastEditTime : 2022-03-31 11:12:43
 * @LastEditors  : BigBigger
-->
<template>
	<div :class="{'upload-hidden': fileList.length >= limit}">
		<el-upload
			action=""
			list-type="picture-card"
			:on-preview="handlePictureCardPreview"
			:on-remove="handleRemove"
			:on-success="handleSuccess"
			:on-error="handleError"
			:http-request="handleUpload"
			:on-exceed="handleExceed"
			:fileList="fileList"
			:before-upload="beforeUpload"
			:limit="limit"
			accept="image/*"
			v-bind="$attrs"
		>
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>

<script>
import { upload } from '@/api/api';

export default {
	name: 'v-upload',
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: [String, Array],
		limit: {
			type: Number,
			default: 1,
		},
		// limit 为 1 时 value 转为字符串
		toStr: {
			type: Boolean,
			default: true,
		},
		maxSize: {
			type: Number,
			default: Number.MAX_SAFE_INTEGER,
		}
	},
	data() {
		return {
			dialogImageUrl: '',
			dialogVisible: false,
			fileList: [],
		};
	},
	watch: {
		value: {
			handler(newValue, oldValue) {
				if (JSON.stringify(oldValue) === JSON.stringify(newValue))
					return;
				if (!newValue) {
					this.fileList = [];
					return;
				}
				if (typeof newValue === 'string') {
					this.fileList = [{ url: newValue }];
					return;
				}
				if (!Array.isArray(newValue)) {
					this.fileList = [];
					return;
				}
				this.fileList = newValue.map((url) => ({ url }));
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		handleRemove(file, fileList) {
			this.changeValue(fileList);
		},
		handleSuccess(res, file, fileList) {
			fileList.forEach((i) => {
				if (i.response) i.url = i.response.data.src;
			});
			this.changeValue(fileList);
		},
		handleError(err, file, fileList) {
			this.$message.error(`文件上传失败`);
			this.changeValue(fileList.filter((i) => i.status !== 'error'));
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleExceed() {
			this.$message.error(`最多上传 ${this.limit} 张图片`);
		},
		handleUpload(data) {
			return upload(data);
		},
		beforeUpload(file) {
			const checkType = /image\/.*/.test(file.type);
			if (!checkType) {
				this.$message.warning('请上传图片');
				return checkType
			}
			const checkSize = file.size <= this.maxSize;
			if (!checkSize) {
				this.$message.warning('文件过大');
				return checkSize
			}
			return  checkType && checkSize;
		},
		changeValue(fileList) {
			const _fileList = fileList.map((i) => i.url);
			this.$emit(
				'change',
				this.limit === 1 && this.toStr ? _fileList[0] : _fileList,
			);
		},
	},
};
</script>

<style lang="less" scoped>
::v-deep .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
::v-deep .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 102px;
}

::v-deep .el-upload-list--picture-card .el-progress {
	width: 80%;
	height: 80%;
	.el-progress-circle {
		width: auto !important;
		height: auto !important;
	}
}

.upload-hidden ::v-deep .el-upload{
	display: none;
}
</style>
