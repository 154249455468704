/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-03-31 10:56:43
 * @LastEditors  : BigBigger
 */
import axios from 'axios'
import store from '@/store/index.js'
import router from '@/router'
import { TIME_OUT } from '@/config/config'
import VueRouter from 'vue-router';
import {Messaage , MessageBox} from 'element-ui'

const requset = axios.create({
  // baseURL: 'http://47.110.77.30:9900',
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: TIME_OUT,
})

// 添加请求拦截器
requset.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    if(config.url === 'https://api.polyv.net/live/v3/channel/common/get-chat-token') {
      return config
    }
    let token = store.state.userInfo ? store.state.userInfo.token : null;
    if (token) {
      config.headers.token = token
    }
    return config;
  }, (error) => {
    // 对请求错误做些什么
    return;
  });

// 添加响应拦截器
requset.interceptors.response.use((response) => {
    // 对响应数据做点什么
    if(response.data.returnCode === '999998') {
      // Dialog.alert({
      //   message: '登录信息已失效，请重新登录',
      // })
      MessageBox.confirm(
        'token已经失效,请重新登录','确定登出', 
        {
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: '重新登录',
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('clearUserInfo')
        router.replace({path: '/course'})
      })
      setTimeout(() => {
        window.location.reload()
      }, 20000)
      // Confirm('token已经失效', '提示', {
      //   confirmButtonText: '重新登录',
      //   closeOnClickModal: false,
      //   closeOnPressEscape: false,
      //   showCancelButton: false,
      //   showClose: false,
      //   type: 'error',
      //   callback: action => {
      //     store.dispatch('clearUserInfo')
      //     router.replace({path: '/course'})
      //   }
      // });
      // store.dispatch('clearUserInfo')
      
    }
    // if(response)
    return response.data;
  }, (error) => {
    // 对响应错误做点什么
    return Promise.reject(error)
  });

export default requset