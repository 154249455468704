/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-02-11 09:23:59
 * @LastEditors  : BigBigger
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element-ui-theme-index.css'
import '@/assets/css/index.css'

Vue.use(ElementUI);
Vue.config.productionTip = false

import VUpload from '@/components/common/Upload.vue';
Vue.component('VUpload', VUpload);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
