<template>
  <div>
    <div class="blod-titme">
      忘记密码
    </div>
    <div class="form">
      <div class="input-box">
        <input class="input" placeholder="请输入手机号" v-model="loginForm.phone" type="text">
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入验证码" v-model="loginForm.validateCode" type="text">
        <GetVsCode :phone="loginForm.phone" />
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入新密码" v-model="loginForm.password" :type="pwd1show ? 'text' : 'password'">
        <div class="toggle-password" @click="pwd1show = !pwd1show">
          <img v-if="!pwd1show" src="@/assets/images/login/pwd-2.png" alt="">
          <img v-if="pwd1show" src="@/assets/images/login/pwd-1.png" alt="">
        </div>
      </div>
      <div class="input-box">
        <input class="input" placeholder="请再次输入新密码" v-model="loginForm.passwordNew" :type="pwd2show ? 'text' : 'password'">
        <div class="toggle-password" @click="pwd2show = !pwd2show">
          <img v-if="!pwd2show" src="@/assets/images/login/pwd-2.png" alt="">
          <img v-if="pwd2show" src="@/assets/images/login/pwd-1.png" alt="">
        </div>
      </div>
    </div>
    <button class="confirm" @click="forgetPassword">完成</button>
    <div class="has-account" @click="$emit('toggletype',0)">已有账号立即登录</div>
  </div>
</template>

<script>
import { forgetPassword } from '@/api/api'
import GetVsCode from '@/components/user/GetVsCode'

export default {
  name: "FindPwd",
  data() {
    return {
      pwd1show: false,
      pwd2show: false,
      loginForm: {
        phone: '',
        validateCode: '',
        password: '',
        passwordNew: ''
      },
      // loginRules: {
      //   phone: [
      //     { required: true, message: '请填写手机号', trigger: 'change' }
      //   ],
      // }
    }
  },
  components: {
    GetVsCode
  },
  methods: {
    checkForm() {
      // let state = true;
      let loginForm = this.loginForm
      if(loginForm.phone === '') {
        this.$message.error('手机号码不能为空')
        return false
      }
      if(loginForm.password === '') {
        this.$message.error('密码不能为空')
        return false
      }
      if(loginForm.passwordNew === '') {
        this.$message.error('新密码不能为空')
        return false
      }
      if(loginForm.validateCode === '') {
        this.$message.error('手机验证码不能为空')
        return false
      }
      if(!/^1[3456789]\d{9}$/.test(loginForm.phone)) {
        this.$message.error('手机号码格式不正确')
        return false
      }
      // if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(loginForm.password)) {
      //   this.$message.error('密码为数字加字母，6-12位')
      //   return false
      // }
      if(loginForm.password !== loginForm.passwordNew) {
        this.$message.error('两次输入的密码不一样')
        return false
      }
      return true
    },
    async forgetPassword() {
      if(!this.checkForm()) {
        return false
      }
      let params = this.loginForm
      let response = await forgetPassword(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.$message.success(response.returnMsg)
      this.$emit('toggletype',0)
      // this.$store.diapatch('toggleDialog',{isShow: false})
    }
  }
}
</script>
<style lang="less" scoped>
.tab{
  display: flex;
  justify-content: space-around;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
  .tab-list{
    border-bottom: 2px solid #fff;
    width: 50px;
    cursor: pointer;
  }
  .tab-list-active{
    border-bottom: 2px solid #FF3F47;
    color: #333333;
    font-weight: bold;
  }
}
.form{
  margin-bottom: 25px;
}
.button-box{
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #999999;
  justify-content: space-between;
  span{
    cursor: pointer;
  }
}
.input-box{
  border-bottom: 1px solid #E8E8E8;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  .input{
    width: 100%;
    height: 58px;
    line-height: 58px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.confirm{
  width: 370px;
  height: 49px;
  line-height: 49px;
  background: #FF3F47;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}
.vcode{
  width: 90px;
  height: 23px;
  background: #eee;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
.toggle-password{
  width: 20px;
  height: 12px;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
.has-account{
  color: #FF3F47;
  margin-top: 34px;
  text-align: center;
  font-size: 19px;
  cursor: pointer;
}
.protocol{
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #6F6F6F;
  cursor: pointer;
  margin-top: 20px;
  img{
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }
}
.blod-titme{
  color: #333333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
</style>
