<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-11-02 16:16:11
 * @LastEditors  : BigBigger
-->
<template>
  <div class="nav-box">
    <div class="nav">
      <div class="nav-mini" v-if="showMiniNav">
        <div class="nav-mini-center">
          <div class="nmc-left">博学官网首页</div>
          <div class="nmc-right">
            <p>微信公众号：博学教育</p>
            <p>咨询电话：400-0160-999</p>
          </div>
        </div>
      </div>
      <div class="nav-content">
        <div class="nc-left">
          <div class="logo" v-if="isJS" @click="logoClick">
            <img src="@/assets/images/logo/logo2.png" alt="博学教育" style="margin-top: 8px;">
          </div>
          <div class="logo" v-else @click="logoClick">
            <img src="@/assets/images/logo/logo.png" alt="博学教育" style="margin-top: 1px;">
          </div>
          <div class="nav-select" v-if="showMiniNav">浙江</div>
          <ul class="nav-list"  v-if="!showMiniNav">
            <!-- <li class="nav-item" @click="jump('http://www.bxgwy.com/')">首页</li> -->
            <li class="nav-item nav-active" @click="navClick('/course')">首页</li>
            <!-- <li class="nav-item">师资</li> -->
          </ul>
        </div>
        <div class="nc-right">
          <div class="tel">
            <img src="@/assets/images/icon/icon-7.png" alt="" />
            <span v-if="isJS">{{ getJSTel }}</span>
            <span v-else>400-0160-999</span>
          </div>
          
            <div class="login" v-if="!getUserInfo">
              <span @click="login(0)">登录</span>
              /
              <span @click="login(2)">注册</span>
            </div>
            <div class="user-info" v-if="getUserInfo">
              <p class="user-tel">{{getUserInfo.name}}</p>
              <el-dropdown @command="handleCommand">
                <div class="avatar" v-if="getUserInfo.headImg">
                  <img :src="getUserInfo.headImg" :alt="getUserInfo.name" />
                </div>
                <div class="avatar" v-else>
                  <img src="@/assets/images/mine/headImg.png">
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="myCourse">我的课程</el-dropdown-item>
                  <el-dropdown-item command="myOrders">我的订单</el-dropdown-item>
                  <el-dropdown-item command="courseProtocol">课程协议</el-dropdown-item>
                  <el-dropdown-item command="myInfo">基本信息</el-dropdown-item>
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          
        </div>
        
      </div>
      <div class="mini-line" v-if="showMiniNav"></div>
      <LoginDialog></LoginDialog>
    </div>
  </div>
</template>
<script>
import LoginDialog from '@/components/user/LoginDialog'
import { logout } from '@/api/api'
export default {
  name: "Nav",
  components: {
    LoginDialog
  },
  data(){
    return{
      isJS:'',
    }
  },
  computed: {
    getUserInfo() {
      if(this.$store.state) {
        return this.$store.state.userInfo || null
      } else {
        return null
      }
    },
    getJSTel() {
      if(this.$store.state) {
        switch(this.$store.state.city) {
          case 2: return '0551-62827270';
          case 3: return '0531-82388001';
          default: return '400-019-0195';
        }
      }
      return '400-019-0195';
    },
    showMiniNav() {
      return this.$route.name === 'Mini'
    }
  },
  mounted(){
    this.isJS = process.env.VUE_APP_AREA === 'js' ? true : false 
  },
  methods: {
    logoClick() {
      if(this.$route.name !== 'Mini') {
        this.navClick('/course')
      }
    },
    jump(url) {
      window.location.href=url
    },
    navClick(val) {
      // this.$router.push({path:val})
      // window.location.href = val
      this.$router.push({path: val})
    },
    login(val = 0) {
      this.$store.dispatch('toggleDialog', {isShow: true, type: val})
    },
    mineClick(val) {
      this.$router.push({path: `/mine?type=${val}`})
      // this.$router.push({path:`/mine`,query:{
      //   type: val
      // }})
    },
    handleCommand(val) {
      switch (val) {
        case 'logout':
          this.logout()
          break;

        case 'myCourse':
          this.mineClick(0)
          break;

        case 'myOrders':
          this.mineClick(1)
          break;

        case 'courseProtocol':
          this.mineClick(2)
          break;

        case 'myInfo':
          this.mineClick(4)
          break;
      
        default:
          break;
      }
    },
    async logout() {
      // let response = await logout({})
      // if(+response.returnCode !== 10001) {
      //   this.$message.error(response.returnMsg)
      //   // return false
      // }
      this.$store.dispatch('clearUserInfo')
      this.$message.success('退出成功')
      if(this.$route.name === 'Mini') {
        this.$router.replace({path: '/mini'})
      } else {
        this.$router.replace({path: '/course'})
      } 
      setTimeout(() => {
        window.location.reload()
      },2000)
    },
    
  },
  filters: {
    phoneFilter(val) {
      if(val) {
        return `${val.substr(0,3)}****${val.substr(7)}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.nav-box{
  min-height: 72px;
  width: 100%;
}
.nav-mini{
  background: #333;
  font-size: 12px;
  color: #fff;
  .nav-mini-center{
    width: 1200px;
    margin: 0 auto;
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nmc-left{
      cursor: pointer;
    }
    .nmc-right{
      display: flex;
      p{
        margin-left: 55px;
      }
    }
  }
}
.nav-select{
  width: 61px;
  height: 26px;
  background: #E43635;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: 26px;
  margin-left: 45px;
}
.mini-line{
  width: 100%;
  height: 5px;
  background: #E43635;
}
.nav{
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  // height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(47, 51, 56, 0.08);
  // position: relative;
  z-index: 50;
  .nav-content{
    width: 1200px;
    height: 72px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nc-left{
      display: flex;
      align-items: center;
      .logo{
        width: 130px;
        height: 55px;
        cursor: pointer;
        overflow: hidden;
        // display: flex;
        // justify-content: center;
        // background: #f00;
        img{
          width: 100%;
          // height: 100%;
        }
      }
      .nav-list{
        color: #5B6B73;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        align-items: center;
        margin-left: 50px;
        .nav-item{
          height: 33px;
          line-height: 33px;
          cursor: pointer;
          // margin-left: 55px;
          font-weight: bold;
        }
        .nav-active{
          color: #FF3F47;
          font-weight: bold;
          position: relative;
          &:before{
            display: block;
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            border-radius: 1.5px;
            background: #FF3F47;
          }
        }
      }
    }
    .nc-right{
      display: flex;
      .tel{
        color: #FF3F47;
        font-size: 18px;
        font-weight: 800;
        display: flex;
        margin-right: 60px;
        align-items: center;
        span{
          line-height: 36px;
        }
        img{
          display: flex;
          width: 25px;
          height: 26px;
          margin-right: 15px;
        }
      }
      .login{
        font-size: 14px;
        font-weight: 400;
        color: #5B6B73;
        height: 36px;
        line-height: 36px;
        span{
          cursor: pointer;
        }
      }
    }
  }
  .user-info{
    display: flex;
    .user-tel{
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      height: 36px;
      line-height: 36px;
      margin-right: 11px;
    }
    .avatar{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  
}
</style>