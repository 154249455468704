<template>
  <div v-if="isJS" class="foot">
      <p>南京浙博教育咨询有限公司 版权所有   苏ICP备16055626号-1     <a href="http://www.beian.miit.gov.cn/">工信部备案管理系统</a></p>
      <p>Copyright©2006-{{new Date().getFullYear()}} www.jsbxgwy.com Inc. All Rights Reserved</p>
  </div>
  <div v-else class="foot">
      <p>浙江博学成公教育科技有限公司 版权所有   浙ICP备16039167号-1    工信部备案管理系统</p>
      <p>Copyright©2006-{{new Date().getFullYear()}} www.bxgwy.com Inc. All Rights Reserved</p>
  </div>
  
</template>
<script>
export default {
  name: "Foot",
  data(){
    return{
      isJS:'',
    }
  },
  mounted(){
    this.isJS = process.env.VUE_APP_AREA === 'js' ? true : false 
  },
}
</script>
<style lang="less">
.foot{
  width:100%;
  background:#333333;
  text-align: center;
  padding:20px 0;
  p{
    font-size: 12px;
    color:#999999;
    padding:8px;
    a{
      font-size: 12px;
      color:#999999;
      text-decoration: none;
    }
  }
}
</style>