<template>
  <div>
    <div class="login" v-if="loginDialogIsShow">
      <div class="content">
        <p class="close" @click="hideLoginDialog">
          <i class="el-icon-close"></i>
        </p>
        <LoginPwd @toggletype="toggleType" v-if="type === 0" />
        <LoginCode @toggletype="toggleType" v-if="type === 1" />
        <Regist @toggletype="toggleType" v-if="type === 2" />
        <FindPwd @toggletype="toggleType" v-if="type === 3" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginPwd from '@/components/user/LoginPwd'
import LoginCode from '@/components/user/LoginCode'
import Regist from '@/components/user/Regist'
import FindPwd from '@/components/user/FindPwd'
export default {
  name: "LoginDialog",
  data() {
    return {
      type: -1, // 0: 密码登录； 1： 验证码登录； 2：注册； 3：忘记密码；
      showDialog: false
    }
  },
  components: {
    LoginPwd,
    LoginCode,
    Regist,
    FindPwd
  },
  mounted() {
    // this.showDialog = this.$store.state.login.loginDialogIsShow || false
  },
  computed: {
    loginDialogIsShow() {
      return this.$store.state.loginDialogIsShow || false
    },
    // loginDialogType() {
    //   console.log('this.$store.state.login.type', this.$store.state.login.type)
    //   return this.$store.state.login.type || 0
    // }
  },
  watch: {
    loginDialogIsShow(val) {
      if(val) {
        this.type = this.$store.state.loginDialogType || 0
      }
    }
  },
  methods: {
    toggleType(val) {
      this.type = val
    },
    hideLoginDialog() {
      this.$store.dispatch('toggleDialog',{isShow: false})
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 90;
  background: rgba(0,0,0,0.3);
  .content{
    width: 370px;
    border-radius: 10px;
    padding: 52px 40px 50px;
    // background: url('../../assets/images/login/bg.png') no-repeat top left, url('../../assets/images/login/bg2.png') no-repeat bottom right #fff;
    background: #fff;
    background-size: 130px auto;
    position: relative;
    .close{
        width: 50px;
        height: 50px;
        line-height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        // background: #f00;
        font-size: 20px;
        font-weight: bold;
        color: #999;
        text-align: center;
    }
  }
}
</style>